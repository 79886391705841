import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
const MainPage = ({ data }: { data: any }) => {
  const router = useRouter();
  useEffect(() => {
    router.replace(`/baby-astrology`);
  }, []);

  return <></>;
};

export default MainPage;
